import React from 'react';
import logo from './logo_wbg.png';
import './App.css';
import { FaInstagram } from 'react-icons/fa';
import { IoConstruct } from "react-icons/io5";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          La página se encuentra en construcción <IoConstruct />
        </p>
        <div>Visítanos en <br></br>
          <a
          className="App-link"
          href="https://www.instagram.com/hogazasbakery/?igsh=YWN6cGFyNWhqOXZh"
          target="_blank"
          rel="noopener noreferrer"
        ><FaInstagram className="instagram-icon" />
          </a> </div>
      </header>
    </div>
  );
}

export default App;
